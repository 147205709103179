<template>
  <div class="home">
    <router-link to="/tools">Tools</router-link>
  </div>
</template>

<script>
export default {
  name: 'Home',
}
</script>

import Vue from 'vue';
import Vuetify, {
    VRow,
    VCol,
    VTextField,
    VTooltip,
    VCheckbox,
    VSelect,
    VBtn,
    VIcon,
} from 'vuetify/lib';
import { Ripple, Intersect, Touch, Resize } from 'vuetify/lib/directives';

import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify, {
    components: { VRow, VTooltip, VCol, VTextField, VCheckbox, VSelect, VBtn, VIcon },
    directives: { Ripple, Intersect, Touch, Resize },
 
});

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#423286',
            secondary: '#ad1126',
            accent: colors.indigo.base, // #3F51B5
          },
        },
      },
});
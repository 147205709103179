<template>
  <v-app>    
    <v-main>      
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import Vue from 'vue';

Vue.prototype.$globals = {
  IsApp: false
}

export default {
  name: 'App',
  data: () => ({
    //
  }),
  mounted: function () {
    this.$globals.IsApp = document.cookie.indexOf("ag-native-app-cache=") >=0;
  }
};
</script>

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import VCurrencyField from 'v-currency-field';

//Globally import VTextField to make it available to v-currency-field
import { VTextField } from 'vuetify/lib'; 

Vue.component('v-text-field', VTextField);

Vue.config.productionTip = false;

Vue.mixin({
  methods: {
    getBrowserLocale: function () {
      return getBrowserLocales[0];
    },
    formatNumber(value) {
      return Number(value).toFixed(2);
    },
    formatCurrency(value, currency) {
      if (value == -0) value = 0;
      return Intl.NumberFormat(this.getBrowserLocale(), {style: "currency", currency: currency}).format(value);
    },
    formatDate(date) {
      var d = new Date(date);
      if (d instanceof Date && !isNaN(d)) {

        let year = d.getFullYear();
        let month = (1 + d.getMonth()).toString().padStart(2, '0');
        let day = d.getDate().toString().padStart(2, '0');
      
        return month + '/' + day + '/' + year;
      }
      return '';
    }

  },
})

Vue.use(VCurrencyField, { 
	locale: getBrowserLocales[0],
	decimalLength: 2,
	autoDecimalMode: true,
	min: null,
	max: null,
	defaultValue: 0,
    valueAsInteger: false,
    allowNegative: false
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

function getBrowserLocales(options = {}) {
  const defaultOptions = {
    languageCodeOnly: false,
  };

  const opt = {
    ...defaultOptions,
    ...options,
  };

  const browserLocales =
    navigator.languages === undefined
      ? [navigator.language]
      : navigator.languages;

  if (!browserLocales) {
    return ["en-US"];
  }

  return browserLocales.map(locale => {
    const trimmedLocale = locale.trim();

    return opt.languageCodeOnly
      ? trimmedLocale.split(/-|_/)[0]
      : trimmedLocale;
  });
}
